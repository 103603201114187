import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Loading from "./loading";
import ImageGallery from "./image-carousel";
import Breadcrumbs from "./BreadcrumbComponent";
import RelatedProducts from "./RelatedProducts";

const ProductPage = () => {
  const [product, setProduct] = useState(null);
  const [categoryPath, setCategoryPath] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { slug } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const queryString = new URLSearchParams({
          consumer_key: "ck_2f7a0664243a2831f245e0b8378bcd99ba9a9b07",
          consumer_secret: "cs_9326252121f42aaa3dce7422a23fa8ee3f7f0449",
        }).toString();

        const response = await fetch(
          `https://test.mangi.org/wordpress/wp-json/wc/v3/products?slug=${slug}&${queryString}`
        );

        if (!response.ok) {
          throw new Error("Product not found");
        }

        const [data] = await response.json();
        setProduct(data);

        // Получаем первую категорию продукта (предполагаем, что она существует)
        const mainCategory = data.categories[0];
        const categoryHierarchy = await fetchCategoryHierarchy(
          mainCategory.id,
          queryString
        );
        setCategoryPath(categoryHierarchy.reverse());
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchCategoryHierarchy = async (categoryId, queryString) => {
      const hierarchy = [];
      let currentCategoryId = categoryId;

      while (currentCategoryId) {
        const response = await fetch(
          `https://test.mangi.org/wordpress/wp-json/wc/v3/products/categories/${currentCategoryId}?${queryString}`
        );
        const categoryData = await response.json();
        hierarchy.push(categoryData);
        currentCategoryId = categoryData.parent || null;
      }

      return hierarchy;
    };

    fetchData();
  }, [slug]);

  if (loading) return <Loading />;
  if (error) return <div>Error: {error}</div>;
  if (!product) return null;

  const imageGallery = product.images.map((img) => img.src);
  const mainCategoryId = product.categories[0].id;

  return (
    <ProductContainer>
      <Breadcrumbs categoryPath={categoryPath} />
      <ImageGallery images={imageGallery} />
      <ProductDetails>
        <ProductName>{product.name}</ProductName>
        <StyledTitle>Product description</StyledTitle>
        <ProductShortDescription
          dangerouslySetInnerHTML={{ __html: product.short_description }}
        />
        <ProductDescription
          dangerouslySetInnerHTML={{ __html: product.description }}
        />
      </ProductDetails>
      <RelatedProducts
        categoryId={mainCategoryId}
        currentProductId={product.id}
      />
    </ProductContainer>
  );
};

export default ProductPage;

const StyledTitle = styled.h2`
  font-size: 2rem;
  margin-top: 20px;
  color: #fff;
  width: 300px;
  border-radius: 100px;
  position: relative;
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #d51603;
`;

const ProductContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px;
  flex-direction: column;

  @media (max-width: 480px) {
    max-width: 90vw;
  }
`;

const ProductDetails = styled.div`
  padding: 20px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 480px) {
    max-width: 90vw;
    padding: 0;
  }
`;

const ProductName = styled.h2`
  font-size: 2rem;
  margin: 0;
  color: #333;
  margin-bottom: 10px;
`;

const ProductShortDescription = styled.div`
  font-size: 1.2rem;
  // margin-bottom: 20px;
  color: #666;
  line-height: 1.4;
`;

const ProductDescription = styled.div`
  font-size: 1rem;
  line-height: 1.5;
  color: #666;

  @media (max-width: 480px) {
    table {
      width: 90vw !important;
    }
  }
`;
