import React from "react";
import { styled } from "styled-components";
import { Link } from "react-router-dom";
import Logo from "./logo";
import logo from "../assets/logo_head_black.png";

const HeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: transparent;
  padding: 1rem 2rem;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(7px);
  height: 100vh;
  width: 20%;
  @media (max-width: 480px) {
    width: 50%;
  }

  box-sizing: border-box;
`;

const LogoContainer = styled.div`
  align-self: flex-start;
  margin-bottom: auto;
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: auto;
`;

const MenuLink = styled(Link)`
  position: relative;
  text-decoration: none;
  color: #000;
  margin: 0.5rem 0;
  font-size: 1.2rem;
  padding-left: 1rem;
  width: 100%;
  text-align: left;

  // Псевдоэлемент для создания маркера
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 6px;
    height: 6px;
    background-color: #000;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover::before,
  &:focus::before {
    opacity: 1;
  }
`;

const HomeHeader = () => {
  const scrollToAnchor = (anchorId, event) => {
    event.preventDefault();
    const anchorElement = document.getElementById(anchorId);
    if (anchorElement) {
      window.scrollTo({
        top: anchorElement.offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <HeaderContainer>
      <LogoContainer>
        <Link to="/">
          <Logo src={logo} alt="Resanta Vietnam" />
        </Link>
      </LogoContainer>
      <Nav>
        <MenuLink to="/">Home</MenuLink>
        <MenuLink to="/#about" onClick={(e) => scrollToAnchor("about", e)}>
          About us
        </MenuLink>
        <MenuLink to="/#contact" onClick={(e) => scrollToAnchor("contact", e)}>
          Contact
        </MenuLink>
        <MenuLink to="/faq">FAQ</MenuLink>
        <MenuLink to="/products">Products</MenuLink>
      </Nav>
    </HeaderContainer>
  );
};

export default HomeHeader;
