import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Breadcrumbs = ({ categoryPath }) => {
  return (
    <BreadcrumbContainer>
      <BreadcrumbLink to="/products">All</BreadcrumbLink>
      {categoryPath.map((category, index) => (
        <React.Fragment key={category.id}>
          <Separator>/</Separator>
          <BreadcrumbLink to={`/category/${category.slug}`}>
            {category.name}
          </BreadcrumbLink>
        </React.Fragment>
      ))}
    </BreadcrumbContainer>
  );
};

export default Breadcrumbs;

const BreadcrumbContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const BreadcrumbLink = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: 768px) {
    display: inline-block;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const Separator = styled.span`
  margin: 0 5px;
  color: #666;
  @media (max-width: 768px) {
    margin: 0 3px;
  }
`;
