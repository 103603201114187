import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import Slider from "react-slick";
import Loading from "./loading";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ProductContainer = styled.div`
  padding: 10px;
  background: #fff;
  border-radius: 15px;
  text-align: center;
  position: relative;
  overflow: hidden;

  &:hover {
    background-color: #d51603;
    .ProductImage {
      opacity: 0.7;
      background: none;
      border: 7px solid #d51603;
    }
    .ProductName {
      visibility: visible;
      opacity: 1;
    }
  }
`;

const ProductImage = styled.img`
  margin-bottom: 10px;
  height: 300px;
  width: 300px;
  justify-content: center;
  object-fit: contain;
  border-radius: 15px;
  background-color: #f5f5f5;
  border: 7px solid #f5f5f5;
  object-fit: contain;
  transition: filter 0.3s;

  width: calc(100% - 15px);
`;

const ProductName = styled.p`
  margin: 0;
  position: absolute;
  bottom: 0;
  width: 90%;
  text-align: center;
  color: white;
  padding: 5px 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s linear;
`;

const Products = styled.div`
  width: 1024px;
  max-width: 100vw;
  margin: auto;
`;

const PopularProducts = ({ state, libraries }) => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const baseUrl = "https://test.mangi.org/wordpress/wp-json/wc/v3/products";
    const queryString = new URLSearchParams({
      per_page: "6",
      status: "publish",
      order: "desc",
      orderby: "popularity",
      _fields: "id,slug,name,images",
      consumer_key: "ck_2f7a0664243a2831f245e0b8378bcd99ba9a9b07",
      consumer_secret: "cs_9326252121f42aaa3dce7422a23fa8ee3f7f0449",
    }).toString();
    fetch(`${baseUrl}?${queryString}`)
      .then((response) => response.json())
      .then((data) => {
        setProducts(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
        setIsLoading(false);
      });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Products>
      <center>
        <h2 style={{ fontSize: "32px" }}>Popular Products</h2>
      </center>
      {isLoading ? (
        <Loading />
      ) : (
        <Slider {...settings}>
          {products.map((product) => (
            <Link to={`/product/${product.slug}`} key={product.id}>
              <ProductContainer>
                <ProductImage
                  className="ProductImage"
                  src={product.images[0].src}
                  alt={product.name}
                />
                <ProductName className="ProductName">
                  {product.name}
                </ProductName>
              </ProductContainer>
            </Link>
          ))}
        </Slider>
      )}
    </Products>
  );
};

export default PopularProducts;
