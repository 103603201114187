import React from "react";
import { styled } from "styled-components";
import { Link } from "react-router-dom";
import placeholderImage from "../images/woocommerce-placeholder-300x300.png";

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 30px; // расстояние между картинками
  row-gap: 80px;
  width: 1024px;
  max-width: 100vw;
  margin: 0 auto;
  margin-top: 20px; // отступ сверху
`;

const StyledLink = styled(Link)`
  flex: 0 1 calc(33.333% - 20px); // 33.333% для трех в ряд и вычитаем gap
  height: 100px; // высота картинки
  border-radius: 50%; // сделать картинку круглой
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin: 0 auto; // автоматические отступы по бокам для центрирования

  // Адаптивность для меньших экранов
  @media (max-width: 600px) {
    flex: 0 1 calc(50% - 20px); // 2 в ряд на маленьких экранах
  }

  @media (max-width: 400px) {
    flex: 0 1 100%; // 1 в ряд на очень маленьких экранах
  }
`;

const Image = styled.img`
  width: 200px;
  height: 200px;
  max-width: 100%;
  object-fit: cover;
`;

const ImagesLinks = ({ state }) => {
  // Список картинок и ссылок
  const images = [
    {
      src: { placeholderImage },
      link: "https://example.com/link-1",
    },
    {
      src: { placeholderImage },
      link: "https://example.com/link-2",
    },
    {
      src: { placeholderImage },
      link: "https://example.com/link-3",
    },
    {
      src: { placeholderImage },
      link: "https://example.com/link-3",
    },
    {
      src: { placeholderImage },
      link: "https://example.com/link-3",
    },
  ];

  return (
    <>
      <center>
        <h2 style={{ fontSize: "32px", marginTop: "5rem", display: "none" }}>
          Where to buy?
        </h2>
      </center>
      <ImageContainer style={{ display: "none" }}>
        {images.map((image, index) => (
          <StyledLink key={index} link={image.link}>
            <Image src={image.src} alt={`Link to ${image.link}`} />
          </StyledLink>
        ))}
      </ImageContainer>
    </>
  );
};

export default ImagesLinks;
