import React, { memo } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useLocation,
} from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import PageError from "./components/page-error";
import Cover from "./components/cover";
import About from "./components/about";
import PopularProducts from "./components/popular-products";
import ImagesLinks from "./components/where-to-buy";
import ContactPopup from "./components/contact-popup";
import Footer from "./components/footer";
import CatalogPage from "./components/product-catalog";
import ProductPage from "./components/product-page";
import CategoryPage from "./components/category-page";
import MobileMenu from "./components/menu";
import SearchComponent from "./components/search-component";
import SearchResults from "./components/search-page";
import logo from "./assets/logo_head_black.png";
import { ThemeProvider } from "./components/ThemeContext";
import FixedContactCircles from "./components/fixed-contact-circles";
import "./App.css";
import FAQ from "./components/FAQ";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  a,
  a:visited {
    color: inherit;
    text-decoration: none;
  }
table {
  border-collapse: collapse;
}
td {
  border: 1px solid #f1f2f3;
  padding: 5px;
}
`;

const App = () => (
  <ThemeProvider>
    <GlobalStyle />
    <Router>
      <ConditionalHeader />
      <Main>
        <Routes>
          {/* <Route
            path="/"
            element={
              <center style={{ paddingTop: "30%" }}>
                <h1>Website is temporarily unavailable</h1>
              </center>
            }
          /> */}
          <Route path="/" element={<HomePageComponents />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/products" element={<CatalogPage />} />
          <Route path="/product/:slug" element={<ProductPage />} />
          <Route path="/category/:slug" element={<CategoryPage />} />
          <Route path="/search" element={<SearchResults />} />
          <Route path="*" element={<PageError />} />
        </Routes>
      </Main>
      <MemoizedFooter />
      <MemoizedConditionalCircles />
    </Router>
  </ThemeProvider>
);

const ConditionalHeader = () => {
  const location = useLocation();
  return location.pathname !== "/" ? <Header /> : null;
};
// const ConditionalFooter = () => {
//   const location = useLocation();
//   return location.pathname !== "/" ? <Footer /> : null;
// };

// const ConditionalCircles = () => {
//   const location = useLocation();
//   return location.pathname !== "/" ? <FixedContactCircles /> : null;
// };

const MemoizedConditionalCircles = memo(FixedContactCircles);
const MemoizedFooter = memo(Footer);

const Header = () => (
  <HeaderContainer>
    <MobileMenu />
    <LogoContainer>
      <Link to="/">
        <Logo src={logo} alt="Resanta Vietnam" />
      </Link>
    </LogoContainer>
    <MenuBlock>
      <Nav>
        <MenuLink to="/">Home</MenuLink>
        <MenuLink to="/products">Products</MenuLink>
        <MenuLink to="/faq">FAQ</MenuLink>
      </Nav>
      <SearchComponent />
    </MenuBlock>
  </HeaderContainer>
);

const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #eee;
  background-color: #d51603;
`;

const MenuBlock = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 480px) {
    position: absolute;
  }
`;

const LogoContainer = styled.div`
  padding: 5px 0;

  @media (max-width: 480px) {
    margin: 0 auto;
  }
`;

const Logo = styled.img`
  height: 50px;
  filter: invert(1);
`;

const Nav = styled.nav`
  display: none;
  align-items: center;

  @media (min-width: 480px) {
    display: flex;
  }
`;

const MenuLink = styled(Link)`
  text-decoration: none;
  color: #fff !important;
  margin: 0 15px;
  font-weight: 500;
  transition: color 0.3s;

  &:hover {
    color: #fff;
  }
`;

const HomePageComponents = () => (
  <>
    <HeadContainer>
      <Cover />
    </HeadContainer>
    <About />
    <PopularProducts />
    <ImagesLinks />
    <ContactPopup />
  </>
);

const HeadContainer = styled.div``;

const Main = styled.div`
  min-height: 80vh;
`;

export default App;
