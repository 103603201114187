import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Loading from "./loading";

const RelatedProducts = ({ categoryId, currentProductId }) => {
  const [similarProducts, setSimilarProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSimilarProducts = async () => {
      try {
        const queryString = new URLSearchParams({
          consumer_key: "ck_2f7a0664243a2831f245e0b8378bcd99ba9a9b07",
          consumer_secret: "cs_9326252121f42aaa3dce7422a23fa8ee3f7f0449",
          category: categoryId,
          per_page: 4, // Загружаем 4 товара, чтобы было больше вариантов после фильтрации
        }).toString();

        const response = await fetch(
          `https://test.mangi.org/wordpress/wp-json/wc/v3/products?${queryString}`
        );

        if (!response.ok) {
          throw new Error("Error fetching similar products");
        }

        const data = await response.json();
        const filteredProducts = data.filter(
          (product) => product.id !== currentProductId
        );
        setSimilarProducts(filteredProducts.slice(0, 3)); // Оставляем только 3 товара
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSimilarProducts();
  }, [categoryId, currentProductId]);

  if (loading) return <Loading />;
  if (error) return <div>Error: {error}</div>;

  return (
    <SimilarProductsContainer>
      {similarProducts.length > 0 && (
        <React.Fragment>
          <StyledTitle>Related products</StyledTitle>
          <ProductsGrid>
            {similarProducts.map((product) => (
              <ProductCard key={product.id}>
                <ProductLink to={`/product/${product.slug}`}>
                  <ProductImage
                    src={product.images[0]?.src}
                    alt={product.name}
                  />
                  <ProductName>{product.name}</ProductName>
                </ProductLink>
              </ProductCard>
            ))}
          </ProductsGrid>
        </React.Fragment>
      )}
    </SimilarProductsContainer>
  );
};

export default RelatedProducts;

const StyledTitle = styled.h2`
  font-size: 2rem;
  // margin-top: 20px;
  color: #fff;
  width: 300px;
  text-align: center;
  border-radius: 100px;
  position: relative;
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #d51603;
`;

const SimilarProductsContainer = styled.div`
  // margin-top: 40px;
  padding: 20px;

  @media (max-width: 480px) {
    padding: 0px;
  }
`;

const ProductsGrid = styled.div`
  display: flex;
  //   justify-content: space-between;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const ProductName = styled.div`
  margin-top: 10px;
  font-size: 1.2rem;
  color: #333;
`;
const ProductCard = styled.div`
  flex: 1;
  text-align: center;
  background-color: #f5f5f5;
  border-radius: 15px;
  transition: 0.05s ease-in-out;

  &:hover {
    background-color: #d51603;

    ${ProductName} {
      color: white;
    }
  }

  @media (min-width: 768px) {
    max-width: 40%;
  }
`;

const ProductLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const ProductImage = styled.img`
  max-width: 100%;
  //   height: auto;
  height: 250px;
  object-fit: contain;
  border-radius: 10px;
`;
