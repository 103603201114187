import React from "react";
import styled, { keyframes } from "styled-components";

const scale = keyframes`
  0% {transform: scaleY(1.0)}
  50% {transform: scaleY(0.4)}
  100% {transform: scaleY(1.0)}
`;

const Loading = () => (
  <Container>
    <div>
      <Bar delay={0} />
      <Bar delay={0.1} />
      <Bar delay={0.2} />
      <Bar delay={0.3} />
      <Bar delay={0.4} />
    </div>
  </Container>
);

export default Loading;

const Bar = styled.div`
  background-color: rgba(12, 17, 43, 0.3);
  width: 4px;
  height: 24px;
  margin: 3px;
  border-radius: 0;
  display: inline-block;
  animation: ${scale} 1s ${({ delay }) => delay}s infinite
    cubic-bezier(0.2, 0.68, 0.18, 1.08);
  animation-fill-mode: both;
`;

const Container = styled.div`
  width: 100%;
  margin: 0;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > * {
    margin-top: 24px;
  }
`;
