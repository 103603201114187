import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const SearchIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
  color: white;
  margin-left: 15px;
`;

const SearchInput = styled.input`
  width: 0;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
  padding: 0;
  padding-bottom: 5px;
  font-size: 16px;
  transition: width 0.4s ease-in-out;
  cursor: pointer;
  &:focus {
    width: 200px;
    cursor: text;
  }

  @media (max-width: 480px) {
    &:focus {
      height: 50px;
      width: 70vw;
      max-width: 70vw;
    }
    height: 50px;
  }

  background-color: #d51603;
  color: #fff;
`;

const SearchComponent = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const inputRef = useRef(null);
  const navigate = useNavigate();

  const handleFocus = () => {
    inputRef.current.focus();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      navigate(`/search?term=${searchTerm}`);
      setSearchTerm("");
    }
  };

  return (
    <SearchContainer onClick={handleFocus}>
      <SearchIcon icon={faSearch} />
      <SearchInput
        ref={inputRef}
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyDown={handleKeyDown}
      />
    </SearchContainer>
  );
};

export default SearchComponent;
