import React from "react";
import styled from "styled-components";
import { FaWhatsapp } from "react-icons/fa";
import Zalo from "../assets/zalo.png";

const ContactBlockContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;

  @media (max-width: 768px) {
    bottom: 10px;
    right: 10px;
  }
`;

const ContactButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  background-color: #25d366;
  color: white;
  border-radius: 50%;
  text-decoration: none;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;

  &:hover {
    background-color: #1ebd58;
  }

  &.zalo {
    background-color: #0088cc;

    &:hover {
      background-color: #007bb5;
    }
  }

  @media (max-width: 768px) {
    width: 60px;
    height: 60px;
  }
`;

const WhatsappIcon = styled(FaWhatsapp)`
  width: 30px;
  height: 30px;

  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
  }
`;

const ZaloIcon = styled.img`
  width: 56px;
  height: 56px;

  @media (max-width: 768px) {
    width: 60px;
    height: 60px;
  }
`;

const FixedContactCircles = () => {
  return (
    <ContactBlockContainer>
      <ContactButton
        href="https://wa.me/84931165705"
        target="_blank"
        rel="noopener noreferrer"
      >
        <WhatsappIcon />
      </ContactButton>
      <ContactButton
        className="zalo"
        href="https://zalo.me/84931165705"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ZaloIcon src={Zalo} />
      </ContactButton>
    </ContactBlockContainer>
  );
};

export default FixedContactCircles;
