// page-error.jsx
import React from "react";
import { useLocation } from "react-router-dom"; // Используйте хук useLocation
import styled from "styled-components";

const description404 = (
  <>
    That page can’t be found{" "}
    <span role="img" aria-label="confused face">
      😕
    </span>
  </>
);

const description = (
  <>
    Don&apos;t panic! Seems like you encountered an error. If this persists,
    <a href="https://community.frontity.org"> let us know </a> or try refreshing
    your browser.
  </>
);

// The 404 page component
const Page404 = () => {
  const location = useLocation(); // Используем useLocation для получения текущего маршрута

  const is404 = location.pathname === "/404"; // Задаем, когда отображать 404
  const title = "Oops! Something went wrong";
  const title404 = "Oops! 404";

  return (
    <Container>
      <Title>{is404 ? title404 : title}</Title>
      <Description>{is404 ? description404 : description}</Description>
    </Container>
  );
};

export default Page404;

const Container = styled.div`
  width: 100%;
  margin: 0;
  // padding: 24px;
  text-align: center;
`;

const Title = styled.h1`
  margin: 0;
  margin-top: 24px;
  margin-bottom: 8px;
  color: rgba(12, 17, 43);
  font-size: 4em;
`;

const Description = styled.div`
  line-height: 1.6em;
  color: rgba(12, 17, 43, 0.8);
  margin: 24px 0;
`;
