import React, { useState } from "react";
import styled from "styled-components";
import FAQImage from "../images/faq.jpg";

const FAQContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  justify-content: center;
  gap: 20px;
`;

const FAQImageContainer = styled.div`
  flex: 1;
  min-width: 250px;
  max-width: 400px;
  text-align: center;
  img {
    width: 100%;
    height: 450px;
    object-fit: cover;
    object-position: 80%;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const AccordionSection = styled.div`
  background: #f9f9f9;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 10px auto;
  flex: 2;
  min-width: 600px;
`;

const AccordionTitle = styled.div`
  background: #d51603;
  color: #fff;
  cursor: pointer;
  padding: 18px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AccordionContent = styled.div`
  background: #fff;
  color: #000;
  padding: 0 18px;
  overflow: hidden;
  transition: max-height 0.3s ease;
`;

const Accordion = ({ title, content, isOpenByDefault }) => {
  const [isOpen, setIsOpen] = useState(isOpenByDefault);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <AccordionSection>
      <AccordionTitle onClick={toggleAccordion}>
        {title}
        <span>{isOpen ? "-" : "+"}</span>
      </AccordionTitle>
      <AccordionContent isOpen={isOpen}>
        {isOpen && <p>{content}</p>}
      </AccordionContent>
    </AccordionSection>
  );
};

const FAQ = () => {
  return (
    <FAQContainer>
      <FAQImageContainer>
        <img src={FAQImage} alt="FAQ" />
      </FAQImageContainer>
      <div>
        <Accordion
          title="How can I place an order?"
          content="You can do so by filling out the 'Contact Us' form on our homepage."
          isOpenByDefault={true}
        />
        <Accordion
          title="Do you offer any discounts for larger orders?"
          content="For larger orders, we offer various volume discounts."
          isOpenByDefault={false}
        />
        <Accordion
          title="Which payment methods do you offer?"
          content="Our customers are wholesale buyers, and therefore, payment for the goods is made through a bank transfer."
          isOpenByDefault={false}
        />
        <Accordion
          title="Can I cancel or modify my order after it has been placed?"
          content="We understand that sometimes things can change, and we want our customers to have the flexibility to adjust their orders if needed. If you have any questions or need to make changes to an order that you have already placed, please don't hesitate to contact our customer service team. We are here to help you find the best possible solution, depending on the current status of your order."
          isOpenByDefault={false}
        />
        <Accordion
          title="Do you provide warranties for the products?"
          content="Yes, we do offer warranties for all of our products to ensure customer satisfaction. However, the length and terms of the warranty can vary depending on the product. Please refer to the product's description or contact us for more information about the specific warranty details."
          isOpenByDefault={false}
        />
        <Accordion
          title="What should I do if I receive a damaged product?"
          content="If you receive a damaged product, there are several options available to you. If the item is still within the warranty period, you can return the damaged product to the seller and request a replacement. Alternatively, if the damage occurred during shipping due to mishandling, you may be entitled to a full refund or a functional replacement product."
          isOpenByDefault={false}
        />
      </div>
    </FAQContainer>
  );
};

export default FAQ;
