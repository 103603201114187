import React, { useEffect, useState, useCallback } from "react";
import { useLocation, Link } from "react-router-dom";
import styled from "styled-components";
import Loading from "./loading";
import placeholderImage from "../images/woocommerce-placeholder-300x300.png";
import LazyLoad from "react-lazyload";

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 90%;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  row-gap: 20px;
  min-height: 90vh;
`;

const ProductCard = styled.div`
  flex: 1 0 33%;
  padding: 10px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex: 1 0 50%;
  }

  @media (max-width: 480px) {
    flex: 1 0 100%;
  }
`;

const Card = styled.div`
  border: 1px solid #eee;
  padding: 10px;
  text-align: center;
  border-radius: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: 400px;
`;

const ProductImage = styled.img`
  width: 100%;
  max-width: 100%;
  height: 300px;
  margin-bottom: 10px;
  object-fit: contain;
`;

const ProductName = styled.h3`
  font-size: 1.2rem;
  color: #333;
  margin-top: auto;
`;

const PaginationDiv = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const PageButton = styled.button`
  margin: 0 5px;
  padding: 5px 10px;
  background-color: ${({ isActive }) => (isActive ? "#8E0E01" : "#D51603")};
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #8e0e01;
  }

  &:focus {
    outline: none;
  }
`;

const PaginationButton = ({ number, isActive, onClick }) => (
  <PageButton isActive={isActive} onClick={() => onClick(number)}>
    {number}
  </PageButton>
);

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const paginationRange = getPaginationRange(currentPage, totalPages);

  return (
    <PaginationDiv>
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === "...") {
          return <span key={`ellipsis-${index}`}>...</span>;
        }

        return (
          <PaginationButton
            key={pageNumber}
            number={pageNumber}
            isActive={currentPage === pageNumber}
            onClick={() => onPageChange(pageNumber)}
          />
        );
      })}
    </PaginationDiv>
  );
};

const getPaginationRange = (currentPage, totalPages) => {
  const range = [1];

  let start = Math.max(2, currentPage - 2);
  let end = Math.min(totalPages - 1, currentPage + 2);

  if (start > 2) {
    range.push("...");
  }

  for (let i = start; i <= end; i++) {
    if (!range.includes(i)) {
      range.push(i);
    }
  }

  if (end < totalPages - 1) {
    range.push("...");
  }

  if (!range.includes(totalPages)) {
    range.push(totalPages);
  }

  return range;
};

const SearchResults = () => {
  const location = useLocation();
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const searchQuery = new URLSearchParams(location.search).get("term");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const [productsCache, setProductsCache] = useState({});

  useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading(true);

      const cacheKey = `${searchQuery}-${currentPage}`;
      if (productsCache[cacheKey]) {
        setProducts(productsCache[cacheKey]);
        setIsLoading(false);
        return;
      }

      const baseUrl = `https://test.mangi.org/wordpress/wp-json/wc/v3/products`;
      const params = new URLSearchParams({
        search: searchQuery,
        order: "desc",
        orderby: "popularity",
        consumer_key: "ck_2f7a0664243a2831f245e0b8378bcd99ba9a9b07",
        consumer_secret: "cs_9326252121f42aaa3dce7422a23fa8ee3f7f0449",
        per_page: 9,
        page: currentPage,
      });

      try {
        const response = await fetch(`${baseUrl}?${params}`);
        if (!response.ok) {
          throw new Error("Products not found");
        }
        const total = response.headers.get("X-WP-TotalPages");
        setTotalPages(parseInt(total, 10));
        const products = await response.json();
        setProducts(products);
        setProductsCache((prevCache) => ({
          ...prevCache,
          [cacheKey]: products,
        }));
      } catch (error) {
        console.error("Failed to load products:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (searchQuery) {
      fetchProducts();
    }
  }, [searchQuery, currentPage, productsCache]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const fetchAndCacheRemainingPages = useCallback(async () => {
    const fetchPage = async (page) => {
      const cacheKey = `${searchQuery}-${page}`;
      if (productsCache[cacheKey]) {
        return;
      }

      const baseUrl = `https://test.mangi.org/wordpress/wp-json/wc/v3/products`;
      const params = new URLSearchParams({
        search: searchQuery,
        order: "desc",
        orderby: "popularity",
        consumer_key: "ck_2f7a0664243a2831f245e0b8378bcd99ba9a9b07",
        consumer_secret: "cs_9326252121f42aaa3dce7422a23fa8ee3f7f0449",
        per_page: 9,
        page: page,
      });

      try {
        const response = await fetch(`${baseUrl}?${params}`);
        if (!response.ok) {
          throw new Error("Products not found");
        }
        const products = await response.json();
        setProductsCache((prevCache) => ({
          ...prevCache,
          [cacheKey]: products,
        }));
      } catch (error) {
        console.error("Failed to load products:", error);
      }
    };

    const pagePromises = [];
    for (let page = 2; page <= totalPages; page++) {
      pagePromises.push(fetchPage(page));
    }

    await Promise.all(pagePromises);
  }, [searchQuery, totalPages, productsCache]);

  useEffect(() => {
    if (totalPages > 1) {
      fetchAndCacheRemainingPages();
    }
  }, [totalPages, fetchAndCacheRemainingPages]);

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {products.length === 0 ? (
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              Not found
            </div>
          ) : (
            <>
              <Grid>
                {products.map((product) => (
                  <ProductCard key={product.id}>
                    <Card>
                      <Link to={`/product/${product.slug}`}>
                        <LazyLoad
                          height={300}
                          offset={100}
                          placeholder={
                            <ProductImage
                              src={placeholderImage}
                              alt="placeholder"
                            />
                          }
                        >
                          <ProductImage
                            src={
                              product.images[0]
                                ? product.images[0].src
                                : placeholderImage
                            }
                            alt={product.name}
                            loading="lazy"
                          />
                        </LazyLoad>
                        <ProductName>{product.name}</ProductName>
                      </Link>
                    </Card>
                  </ProductCard>
                ))}
              </Grid>
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default SearchResults;
