import React from "react";
import { styled } from "styled-components";

const FooterContainer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: #d51603;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
  max-width: 100vw;
`;

const ContactInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1024px;
  max-width: 100vw;
  background-color: white;
  padding: 20px;
  border-radius: 100px;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 20px;
  @media (max-width: 480px) {
    flex-direction: column;
    padding: 0;
    width: calc(100% - 20px);
    gap: 10px;
    padding-bottom: 20px;
    padding-top: 0px;
    border-radius: 15px;
    padding-top: 20px;
  }
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  // margin-right: 2rem;

  &:hover {
    color: #666;
  }

  &:last-child {
    margin-right: 0;
  }
  font-size: 1.2rem;
`;

const Icon = styled.i`
  margin-right: 0.5rem;
`;

const RightsText = styled.p`
  color: #fff;
  font-size: 0.9rem;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <ContactInfo>
        <div>
          <span style={{ fontSize: "1.2rem" }}>Get in touch with us</span>
        </div>
        <Link href="mailto:info@resanta.vn">
          <Icon className="fa fa-envelope-o" aria-hidden="true"></Icon>
          info@resanta.vn
        </Link>
        <Link href="tel:+84931165705">
          <Icon className="fa fa-phone" aria-hidden="true"></Icon>
          +84 931165705
        </Link>
      </ContactInfo>
      <RightsText>All rights reserved © 2024 Resanta Vietnam</RightsText>
    </FooterContainer>
  );
};

export default Footer;
