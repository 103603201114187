import React, { useEffect, useState, useCallback, useMemo } from "react";
import styled from "styled-components";
import Loading from "./loading";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./sidebar-categories";
import placeholderImage from "../images/woocommerce-placeholder-300x300.png";

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 90%;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  row-gap: 20px;
  width: 90%;
  min-height: 90vh;
`;

const ProductCard = styled.div`
  flex: 1 0 30%; /* Уменьшил с 33% до 30% для более точного распределения */
  padding: 10px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex: 1 0 45%; /* Уменьшил с 50% до 45% */
  }

  @media (max-width: 480px) {
    flex: 1 0 90%; /* Уменьшил с 100% до 90% */
  }
`;

const Card = styled.div`
  border: 1px solid #eee;
  padding: 10px;
  text-align: center;
  border-radius: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: 400px;
  box-sizing: border-box; /* Добавил box-sizing */
`;

const ProductImage = styled.img`
  width: 100%;
  max-width: 100%;
  height: 300px;
  margin-bottom: 10px;
  object-fit: contain;
`;

const ProductName = styled.h3`
  font-size: 1.2rem;
  color: #333;
  margin-top: auto;
`;

const PaginationDiv = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const PageButton = styled.button`
  margin: 0 5px;
  padding: 5px 10px;
  background-color: ${({ isActive }) => (isActive ? "#8E0E01" : "#D51603")};
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #8e0e01;
  }

  &:focus {
    outline: none;
  }
`;

const Content = styled.div`
  display: flex;
  min-height: 90svh;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

const ProductsGrid = ({ displayedProducts, preloadedImages }) => {
  if (!displayedProducts.length) {
    return <Loading />;
  }

  return (
    <Grid>
      {displayedProducts.map((product) => (
        <ProductCard key={product.id}>
          <Card>
            <Link to={`/product/${product.slug}`}>
              <ProductImage
                src={preloadedImages[product.id] || placeholderImage}
                alt={product.name}
              />
              <ProductName>{product.name}</ProductName>
            </Link>
          </Card>
        </ProductCard>
      ))}
    </Grid>
  );
};

const PaginationButton = ({ number, isActive, onClick }) => (
  <PageButton isActive={isActive} onClick={() => onClick(number)}>
    {number}
  </PageButton>
);

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const paginationRange = useMemo(
    () => getPaginationRange(currentPage, totalPages),
    [currentPage, totalPages]
  );

  return (
    <PaginationDiv>
      {paginationRange.map((pageNumber, index) =>
        pageNumber === "..." ? (
          <span key={`ellipsis-${index}`}>...</span>
        ) : (
          <PaginationButton
            key={pageNumber}
            number={pageNumber}
            isActive={currentPage === pageNumber}
            onClick={() => onPageChange(pageNumber)}
          />
        )
      )}
    </PaginationDiv>
  );
};

const getPaginationRange = (currentPage, totalPages) => {
  const range = [1];
  let start = Math.max(2, currentPage - 2);
  let end = Math.min(totalPages - 1, currentPage + 2);

  if (start > 2) range.push("...");
  for (let i = start; i <= end; i++) {
    if (!range.includes(i)) range.push(i);
  }
  if (end < totalPages - 1) range.push("...");
  if (!range.includes(totalPages)) range.push(totalPages);

  return range;
};

const CatalogPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const initialPage = parseInt(queryParams.get("page"), 10) || 1;

  const [currentPage, setCurrentPage] = useState(initialPage);
  const [totalPages, setTotalPages] = useState(1);
  const [preloadedProducts, setPreloadedProducts] = useState([]);
  const [preloadedImages, setPreloadedImages] = useState({});
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [isLoadingInitial, setIsLoadingInitial] = useState(true);
  const [isBatchLoading, setIsBatchLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);

  const fetchTotalPages = useCallback(async () => {
    const baseUrl = "https://test.mangi.org/wordpress/wp-json/wc/v3/products";
    const queryString = new URLSearchParams({
      per_page: "6",
      page: "1",
      status: "publish",
      order: "desc",
      orderby: "popularity",
      _fields: "id",
      consumer_key: "ck_2f7a0664243a2831f245e0b8378bcd99ba9a9b07",
      consumer_secret: "cs_9326252121f42aaa3dce7422a23fa8ee3f7f0449",
    }).toString();

    try {
      const response = await fetch(`${baseUrl}?${queryString}`);
      if (!response.ok) {
        throw new Error("Failed to fetch total pages");
      }

      const total = response.headers.get("X-WP-TotalPages");
      setTotalPages(parseInt(total, 10));
    } catch (error) {
      console.error("Error fetching total pages:", error);
    }
  }, []);

  const fetchProductsAndImages = useCallback(
    async (startPage, endPage) => {
      const allProducts = [];
      const imageMap = {};

      const fetchPage = async (page) => {
        const baseUrl =
          "https://test.mangi.org/wordpress/wp-json/wc/v3/products";
        const queryString = new URLSearchParams({
          per_page: "6",
          page: page.toString(),
          status: "publish",
          order: "desc",
          orderby: "popularity",
          _fields: "id,slug,name,images",
          consumer_key: "ck_2f7a0664243a2831f245e0b8378bcd99ba9a9b07",
          consumer_secret: "cs_9326252121f42aaa3dce7422a23fa8ee3f7f0449",
        }).toString();

        try {
          const response = await fetch(`${baseUrl}?${queryString}`);
          if (!response.ok) {
            throw new Error(`Failed to fetch products for page ${page}`);
          }

          const products = await response.json();
          allProducts.push(...products);

          const imagePromises = products.map((product) => {
            return new Promise((resolve) => {
              const img = new Image();
              img.src = product.images[0]
                ? product.images[0].src
                : placeholderImage;
              img.onload = () => resolve({ id: product.id, src: img.src });
            });
          });

          const imageResults = await Promise.all(imagePromises);
          imageResults.forEach(({ id, src }) => {
            imageMap[id] = src;
          });
        } catch (error) {
          console.error(
            `Failed to preload products and images for page ${page}:`,
            error
          );
        }
      };

      const pagePromises = [];
      for (let page = startPage; page <= endPage; page++) {
        if (page > totalPages) {
          setHasMoreData(false);
          break;
        }
        pagePromises.push(fetchPage(page));
      }

      try {
        await Promise.all(pagePromises);
        setPreloadedProducts((prevProducts) => {
          const newProducts = [...prevProducts];

          allProducts.forEach((product) => {
            if (!newProducts.some((p) => p.id === product.id)) {
              newProducts.push(product);
            }
          });

          return newProducts;
        });

        setPreloadedImages((prevImages) => ({ ...prevImages, ...imageMap }));
        setIsBatchLoading(false);
      } catch (error) {
        console.error("Failed to preload all products and images:", error);
        setIsBatchLoading(false);
      }
    },
    [totalPages]
  );

  useEffect(() => {
    fetchTotalPages();
  }, [fetchTotalPages]);

  useEffect(() => {
    fetchProductsAndImages(1, 1).then(() => setIsLoadingInitial(false));
  }, [fetchProductsAndImages]);

  useEffect(() => {
    if (totalPages > 1 && hasMoreData && !isBatchLoading) {
      setIsBatchLoading(true);
      const nextBatchStart = Math.floor(preloadedProducts.length / 6) + 1;
      fetchProductsAndImages(nextBatchStart, nextBatchStart + 2);
    }
  }, [
    totalPages,
    fetchProductsAndImages,
    preloadedProducts.length,
    hasMoreData,
    isBatchLoading,
  ]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const page = parseInt(queryParams.get("page"), 10) || 1;
    setCurrentPage(page);
  }, [location]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * 6;
    const endIndex = startIndex + 6;
    setDisplayedProducts(preloadedProducts.slice(startIndex, endIndex));
  }, [currentPage, preloadedProducts]);

  const handlePageChange = useCallback(
    (pageNumber) => {
      setCurrentPage(pageNumber);
      navigate(`?page=${pageNumber}`);
    },
    [navigate]
  );

  if (isLoadingInitial) {
    return <Loading />;
  }

  return (
    <div>
      <Content>
        <Sidebar />
        <ProductsGrid
          displayedProducts={displayedProducts}
          preloadedImages={preloadedImages}
        />
      </Content>
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default CatalogPage;
