import React, { createContext, useState } from "react";

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState({
    menu: [
      ["Home", "/"],
      ["FAQ", "/faq"],
      ["Products", "/products/"],
    ],
  });

  return (
    <ThemeContext.Provider value={{ state: { theme }, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeProvider };
