import React, { useState } from "react";
import styled from "styled-components";

const Slider = styled.div`
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  overflow: hidden;
  text-align: center;
`;

const SliderContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Image = styled.img`
  width: 100%;
  height: 500px;
  object-fit: contain;

  @media (max-width: 480px) {
    height: 250px;
  }
`;

const Arrow = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 2em;
  cursor: pointer;
  padding: 0.5em;

  &.left-arrow {
    left: 0;
  }

  &.right-arrow {
    right: 0;
  }
`;

const Dots = styled.div`
  text-align: center;
  margin-top: 10px;
`;

const Dot = styled.span`
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  cursor: pointer;

  &.active {
    background-color: #717171;
  }
`;

const ImageGallery = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const newIndex = (currentIndex - 1 + images.length) % images.length;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const newIndex = (currentIndex + 1) % images.length;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  return (
    <Slider>
      {images.length > 1 && (
        <Arrow className="left-arrow" onClick={prevSlide}>
          &#9664;
        </Arrow>
      )}
      <SliderContent>
        <Image src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} />
      </SliderContent>
      {images.length > 1 && (
        <Arrow className="right-arrow" onClick={nextSlide}>
          &#9654;
        </Arrow>
      )}
      {images.length > 1 && (
        <Dots>
          {images.map((_, index) => (
            <Dot
              key={index}
              className={index === currentIndex ? "active" : ""}
              onClick={() => goToSlide(index)}
            ></Dot>
          ))}
        </Dots>
      )}
    </Slider>
  );
};

export default ImageGallery;
