import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { CloseIcon, HamburgerIcon } from "./menu-icon";
import MenuModal from "./menu-modal";

const GlobalStyle = createGlobalStyle`
  body {
    overflow-y: hidden;
  }
`;

function MobileMenu() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const menu = [
    ["Home", "/"],
    ["About us", "/about"],
    ["Contact", "/contact"],
    ["FAQ", "/faq"],
    ["Products", "/products/"],
  ];

  if (menu?.length === 0) return null;

  return (
    <>
      <MenuToggle onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}>
        {isMobileMenuOpen ? (
          <>
            <GlobalStyle />
            <CloseIcon color="black" size="20px" />
          </>
        ) : (
          <HamburgerIcon color="white" size="24px" />
        )}
      </MenuToggle>
      {isMobileMenuOpen && <MenuModal />}
    </>
  );
}

const MenuToggle = styled.button`
  position: absolute;
  right: 24px;
  top: 24px;
  background: transparent;
  border: 0;
  color: white;
  z-index: 5;
  height: 60px;
  width: 40px;
  display: none;

  @media (max-width: 560px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default MobileMenu;
