import React, { useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

// Assuming there's a ThemeContext providing the theme state
import { ThemeContext } from "./ThemeContext";

const MenuModal = (props) => {
  const { state } = useContext(ThemeContext);
  const { menu } = state.theme;
  const isThereLinks = menu?.length > 0;

  return (
    <div {...props}>
      <MenuOverlay />
      <MenuContent as="nav">
        {isThereLinks &&
          menu.map(([name, link]) => (
            <MenuLink
              key={name}
              to={link}
              aria-current={
                window.location.pathname === link ? "page" : undefined
              }
            >
              {name}
            </MenuLink>
          ))}
      </MenuContent>
    </div>
  );
};

const MenuOverlay = styled.div`
  background-color: #f1f2f3;
  width: 100vw;
  height: 100vh;
  overflow: hidden auto;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
`;

const MenuContent = styled.div`
  z-index: 3;
  position: relative;
`;

const MenuLink = styled(Link)`
  width: 100%;
  display: inline-block;
  outline: 0;
  font-size: 20px;
  text-align: left;
  padding: 1.2rem 0;
  margin-left: 30px;

  &:hover,
  &:focus {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &[aria-current="page"] {
    color: #fdbe41;
    font-weight: bold;
  }
`;

export default MenuModal;
