import React from "react";
import { styled } from "styled-components";
import HomeHeader from "./home-page-nav";
import backgroundImage from "../images/cover.webp";

const CoverContainer = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: top center;
  @media (max-width: 480px) {
    background-position: 65%;
  }

  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const Cover = () => (
  <CoverContainer>
    <HomeHeader />
  </CoverContainer>
);

export default Cover;
