import React from "react";
import { styled } from "styled-components";
import { Formik, Form, Field, ErrorMessage, useField } from "formik";
import axios from "axios";
import * as Yup from "yup";

const PopupContainer = styled.div`
  display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  align-items: center;
  justify-content: center;
`;

const PopupContent = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  max-width: 500px;
  width: 100%;
  position: relative;
  padding-top: 8px;
`;

const CloseButton = styled.button`
  position: relative;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  margin-left: calc(100% - 20px);
  margin-bottom: 10px;
`;

const StyledInput = styled(Field)`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const StyledTextArea = styled(Field)`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  background-color: #d51603;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  color: white;
  &:hover {
    background-color: #8e0e01;
  }
  &:focus {
    outline: none;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ErrorText = styled.div`
  color: red;
  font-size: 0.875em;
  margin-top: -15px;
  margin-bottom: 15px;
`;

const Textarea = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <StyledTextArea {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};

const ContactSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  phone: Yup.string()
    .matches(
      /^\+84\d{9}$/,
      "Phone number must be exactly 11 digits including the prefix +84"
    )
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  message: Yup.string().required("Message is required"),
});

const ContactPopup = ({ state }) => {
  const [isVisible, setIsVisible] = React.useState(false);

  return (
    <>
      <div
        id="contact"
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "3rem",
          gap: "20px",
          justifyContent: "center",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <h2 style={{ fontSize: "32px" }}>Have questions?</h2>
        <button
          style={{
            border: "none",
            backgroundColor: "#D51603",
            borderRadius: "25px",
            width: "150px",
            height: "50px",
            color: "white",
            fontSize: "22px",
            cursor: "pointer",
          }}
          onClick={() => setIsVisible(true)}
        >
          Contact us
        </button>
      </div>

      <PopupContainer isVisible={isVisible}>
        <PopupContent>
          <CloseButton onClick={() => setIsVisible(false)}>&times;</CloseButton>
          <Formik
            initialValues={{ name: "", phone: "", email: "", message: "" }}
            validationSchema={ContactSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              const formData = new URLSearchParams();
              Object.keys(values).forEach((key) => {
                formData.append(key, values[key]);
              });

              console.log("Sending data:", formData.toString());

              axios({
                method: "post",
                url: "https://test.mangi.org/submit.php",
                data: formData,
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                },
              })
                .then((response) => {
                  console.log("Success:", response.data);
                  alert("Form successfully submitted.");
                  setIsVisible(false);
                  resetForm();
                })
                .catch((error) => {
                  console.error("Error submitting the form: ", error);
                  alert("Form failed to submit");
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
          >
            {({ isSubmitting, setFieldValue, handleBlur }) => (
              <StyledForm>
                <StyledInput type="text" name="name" placeholder="Name" />
                <ErrorMessage name="name" component={ErrorText} />

                <StyledInput
                  type="tel"
                  name="phone"
                  placeholder="Phone (+84)"
                  onChange={(e) => {
                    const value = e.target.value;
                    if (!value.startsWith("+84")) {
                      setFieldValue(
                        "phone",
                        "+84" + value.replace(/^\+84/, "")
                      );
                    } else {
                      setFieldValue("phone", value);
                    }
                  }}
                  onBlur={(e) => {
                    const value = e.target.value;
                    if (!value.startsWith("+84")) {
                      setFieldValue(
                        "phone",
                        "+84" + value.replace(/^\+84/, "")
                      );
                    }
                    handleBlur(e);
                  }}
                />
                <ErrorMessage name="phone" component={ErrorText} />

                <StyledInput type="email" name="email" placeholder="Email" />
                <ErrorMessage name="email" component={ErrorText} />

                <Textarea name="message" placeholder="Message" />
                <ErrorMessage
                  id="message"
                  name="message"
                  component={ErrorText}
                />

                <SubmitButton type="submit" disabled={isSubmitting}>
                  Send
                </SubmitButton>
              </StyledForm>
            )}
          </Formik>
        </PopupContent>
      </PopupContainer>
    </>
  );
};

export default ContactPopup;
