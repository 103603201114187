import React from "react";
import { styled } from "styled-components";
import backgroundImage from "../images/about.webp";

const AboutContainer = styled.div`
  width: 767px;
  max-width: 100vw;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: left bottom;
  margin: 4rem auto;
  border-radius: 15px;
  overflow: hidden;
  @media (max-width: 480px) {
    border-radius: 0;
  }
`;

const TextContainer = styled.div`
  max-width: 40%;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  padding: 2rem;
  color: #333;
  font-family: Arial, sans-serif;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 20px;
  @media (max-width: 480px) {
    max-width: 100%;
  }
`;

const About = ({ state }) => {
  return (
    <div id="about">
      <AboutContainer>
        <TextContainer>
          <p>
            Welcome to Resanta, your trusted destination for top-quality
            electrical equipment. We are proud to provide a wide range of
            high-end electrical products to suit your needs. With our commitment
            to excellence and customer satisfaction, we aim to offer the best
            possible solutions for both individuals and businesses. Our team of
            experts is dedicated to helping you navigate our selection of
            electrical products, ensuring that you find exactly what you need.
            Trust Resanta for all your electrical equipment requirements, and
            experience the difference that quality makes. Come visit us today
            and discover why we are the leading source for reliable
            technological solutions.
          </p>
        </TextContainer>
      </AboutContainer>
    </div>
  );
};

export default About;
