import React from "react";
import { styled } from "styled-components";
import { Link } from "react-router-dom";

const LogoImage = styled.img`
  max-width: 200px;
  width: 100%;
  @media (max-width: 480px) {
    max-width: 150px;
  }

  height: auto;
  // filter: invert(1);
`;

const LogoText = styled.h1`
  margin: 0;
  font-size: 24px;
`;

const Logo = ({ src, alt, isText = false, text = null }) => {
  return (
    <Link to="/">
      {isText ? (
        <LogoText>{text}</LogoText>
      ) : (
        <LogoImage src={src} alt={alt || "Logo"} />
      )}
    </Link>
  );
};

export default Logo;
