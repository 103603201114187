import React, { useState, useEffect } from "react";
import { styled } from "styled-components";
import { Link } from "react-router-dom";

const SidebarContainer = styled.div`
  max-width: 350px;
  padding: 20px;
  flex: 1 0 25%;

  @media (max-width: 768px) {
    width: calc(100vw - 40px);
    max-width: 100vw;

    h2 {
      cursor: pointer;
      margin-bottom: ${({ isOpen }) => (isOpen ? "10px" : "0")};
    }

    .accordion {
      display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    }
  }
`;

const Accordion = styled.div``;

const AccordionTab = styled.div`
  position: relative;
  cursor: auto;
  padding: 10px;
  user-select: none;
  &:hover {
    background-color: #f0f0f0;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
`;

const CategoryLink = styled(Link)`
  color: #333;
  text-decoration: none;
  flex-grow: 1;
  &:hover {
    text-decoration: underline;
  }
`;

const CategoryName = styled.span`
  flex-grow: 1;
`;

const Arrow = styled.span`
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 5px;
  vertical-align: middle;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #333 transparent transparent transparent;
  transform: ${({ isActive }) => (isActive ? "rotate(180deg)" : "none")};
  transition: transform 0.3s ease;
  display: inline-block;
  cursor: pointer;
`;

const AccordionContent = styled.div`
  padding: 10px;
  display: none;
  &.active {
    display: block;
  }
`;

const decodeHtmlEntities = (text) => {
  const entities = {
    "&amp;": "&",
  };
  return text.replace(/&amp;/g, (entity) => entities[entity]);
};

const fetchAllCategories = async (url, params, allCategories = []) => {
  const response = await fetch(`${url}?${params.toString()}`);
  if (!response.ok) {
    throw new Error("Error fetching categories");
  }

  const newCategories = await response.json();
  allCategories = [...allCategories, ...newCategories];

  if (newCategories.length === 100) {
    params.set("page", (parseInt(params.get("page"), 10) + 1).toString());
    return fetchAllCategories(url, params, allCategories);
  }
  return allCategories;
};

const CategoryAccordion = ({ categories }) => {
  const [activeIds, setActiveIds] = useState([]);

  const toggleAccordion = (e, id) => {
    e.stopPropagation();
    setActiveIds((currentActiveIds) => {
      // Проверяем, содержит ли массив текущий id
      if (currentActiveIds.includes(id)) {
        // Если содержит, удаляем его (закрываем вкладку)
        return currentActiveIds.filter((activeId) => activeId !== id);
      } else {
        // Если не содержит, добавляем его (открываем вкладку)
        return [...currentActiveIds, id];
      }
    });
  };

  return (
    <Accordion>
      {categories.map((category) => {
        const hasChildren = category.children && category.children.length > 0;
        const isActive = activeIds.includes(category.id); // Проверяем активность элемента
        return (
          <div key={category.id}>
            <AccordionTab>
              <CategoryName>
                <CategoryLink to={`/category/${category.slug}`}>
                  {decodeHtmlEntities(category.name)}
                </CategoryLink>
              </CategoryName>
              {hasChildren && (
                <Arrow
                  onClick={(e) => toggleAccordion(e, category.id)}
                  isActive={isActive}
                />
              )}
            </AccordionTab>
            {hasChildren && (
              <AccordionContent className={isActive ? "active" : ""}>
                <CategoryAccordion categories={category.children} />
              </AccordionContent>
            )}
          </div>
        );
      })}
    </Accordion>
  );
};

const Sidebar = ({ state }) => {
  const [categories, setCategories] = useState([]);
  const [isOpen, setIsOpen] = useState(false); // Добавлено состояние для управления видимостью аккордеона

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const params = new URLSearchParams({
          consumer_key: "ck_2f7a0664243a2831f245e0b8378bcd99ba9a9b07",
          consumer_secret: "cs_9326252121f42aaa3dce7422a23fa8ee3f7f0449",
          per_page: "100",
          page: "1",
          hide_empty: true,
        });
        const allCategories = await fetchAllCategories(
          "https://test.mangi.org/wordpress/wp-json/wc/v3/products/categories",
          params
        );
        setCategories(buildCategoryTree(allCategories));
      } catch (error) {
        console.error("Failed to load categories:", error);
      }
    };

    fetchData();
  }, []);

  // Функция для создания дерева категорий из плоского массива
  function buildCategoryTree(list) {
    let temp = {};
    let tree = [];
    for (let i = 0; i < list.length; i++) {
      if (list[i].slug === "uncategorized") {
        continue;
      }

      temp[list[i].id] = list[i];
      temp[list[i].id].children = [];
    }
    for (let i = 0; i < list.length; i++) {
      if (temp[list[i].parent] && list[i].slug !== "uncategorized") {
        temp[list[i].parent].children.push(temp[list[i].id]);
      } else if (list[i].parent === 0 && list[i].slug !== "uncategorized") {
        tree.push(temp[list[i].id]);
      }
    }
    return tree;
  }

  return (
    <SidebarContainer isOpen={isOpen}>
      <h2 onClick={toggleAccordion}>Categories →</h2>
      <div className="accordion">
        <CategoryAccordion categories={categories} />
      </div>
    </SidebarContainer>
  );
};

export default Sidebar;
